// @ts-nocheck
import "./styles.scss";
import QrComponent from "pages/Home/QrComponent";
// @ts-ignore
// import { useEffect, useState } from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import clsx from "clsx";
import CoinsGsap from "pages/Home/CoinsGsap";
import useIsVisible from "hooks/useIsVisible";
import { useTranslation } from "react-i18next";

function Section1() {
  const { t } = useTranslation();
  const { elementRef } = useIsVisible("");
  // const [isShow2, setIsShow2] = useState(false);
  const { width } = useWindowDimensions();

  // useEffect(() => {
  //   setIsShow2(false);
  //   setTimeout(() => {
  //     setIsShow2(true);
  //   }, 100);
  // }, [width]);

  return (
    <>
      <div className="first-section mb128 canvas-container">
        <div
          className={clsx(
            { row: width > 1200 },
            { column: width <= 1200 },
            "flex-wrap gap64",
          )}
        >
          <div className="left">
            <div className="row flex-wrap mb32 gap32">
              <img
                src={require("../../assets/icons/logo.jpg")}
                width="128px"
                height="128px"
                alt="logo"
              />
              <QrComponent isMobileButton={false} />
            </div>
            <h1 className="medium90-text mb16" ref={elementRef}>
              {t("Borbalo")}
            </h1>
            <div
              style={{
                minHeight: 60,
              }}
            >
              <h2 className="medium50-text">{t("The App for Drivers")}</h2>
            </div>
          </div>
          <CoinsGsap />
          {/* {isShow2 ? <CoinsGsap /> : <div className="right" />} */}
        </div>
      </div>
    </>
  );
}

export default Section1;
