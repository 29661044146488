import { Outlet } from "react-router-dom";
import FacebookSvg from "./assets/icons/facebook.svg";
import TiktokSvg from "./assets/icons/tiktok.svg";
import YoutubeSvg from "./assets/icons/youtube.svg";

import "./styles.scss";
import clsx from "clsx";
import QrComponent from "pages/Home/QrComponent";
import Home from "pages/Home";
import AboutUs from "pages/AboutUs";
import { Header } from "Header";
import useIsVisible from "hooks/useIsVisible";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useEffect, useRef } from "react";
import { useInViewport } from "react-in-viewport";
import { isAndroid, isIOS } from "react-device-detect";

export const navBar = [
  {
    path: "/",
    label: "Borbalo",
    Element: <Home />,
  },
  {
    path: "/aboutUs",
    label: "About Us",
    Element: <AboutUs />,
  },
  // {
  //   path: "/club",
  //   label: "კლუბი",
  //   Element: <div></div>,
  // },
  // {
  //   path: "/business",
  //   label: "ბორბალო ბიზნესისთვის",
  //   Element: <div></div>,
  // },
  // {
  //   path: "/other",
  //   label: "სხვა",
  //   Element: <div></div>,
  // },
];

const RouteWithNavBar = () => {
  // TODO: change this
  const mobileQrComponentRef = useRef<HTMLDivElement>(null);
  const isMobile = isIOS || isAndroid;
  const qrComponentRef = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(
    qrComponentRef,
    {},
    { disconnectOnLeave: false },
    {},
  );
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const id = window.location.href.includes("aboutUs") ? "Download" : "Download";

  const { elementRef } = useIsVisible(id);

  useEffect(() => {
    if (enterCount > 0 && mobileQrComponentRef.current) {
      mobileQrComponentRef.current.className = inViewport
        ? "dowload-app-wrapper"
        : "dowload-app-wrapper active";
    }
  }, [inViewport, enterCount]);

  const texts1 = [
    {
      text: "App",
      className: "regular18-text color-grey",
      link: undefined,
    },
    {
      text: "Privacy",
      className: "medium18-text",
      link: "/privacy-policy",
    },
    {
      text: "Terms",
      className: "medium18-text",
      link: "/terms-of-use",
    },
    {
      text: "EULA",
      className: "medium18-text",
      link: "/eula",
    },
  ];

  const texts2 = [
    {
      text: "Company",
      className: "regular18-text color-grey",
    },
    {
      text: "About us",
      className: "medium18-text",
      onClick: (e: any) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
    {
      text: "Contact",
      className: "medium18-text color-grey",
      link: undefined,
    },
    {
      text: "Parent Company",
      className: "medium18-text color-grey",
      link: undefined,
    },
  ];

  return (
    <>
      {isMobile && (
        <div
          ref={mobileQrComponentRef}
          id="mobile-qr-component"
          className="dowload-app-wrapper active"
          style={{
            left: width / 2 - 104,
          }}
        >
          <QrComponent isMobileButton={true} />
        </div>
      )}
      <Header />
      <Outlet />
      <footer>
        <div className="row flex-wrap jc-space-between gap32 mb64 w100">
          <div className="row gap32">
            <img
              src={require("./assets/icons/logo.jpg")}
              alt="footer-logo"
              width={126}
              height={126}
              className="footer-logo"
            />
            <h2
              id={id}
              className="medium50-text color-grey mw415"
              ref={elementRef}
            >
              {t("Save Money Easily")}
            </h2>
          </div>
          <div ref={qrComponentRef}>
            <QrComponent isMobileButton={true} />
          </div>
        </div>
        <div className="row flex-wrap jc-space-between gap32 flex1 mb64">
          <div className="row flex1 gap32 flex-wrap">
            <div className="column flex1">
              {texts1.map(text =>
                text.link ? (
                  <a
                    href={text.link}
                    target="_blank"
                    rel="noreferrer"
                    key={text.text}
                  >
                    <p className={clsx([text.className, "mb16"])}>
                      {t(text.text)}
                    </p>
                  </a>
                ) : (
                  <p className={clsx([text.className, "mb16"])} key={text.text}>
                    {t(text.text)}
                  </p>
                ),
              )}
            </div>
            <div className="column flex1">
              {texts2.map(text => (
                <a
                  href={text.link ?? "#"}
                  rel="noreferrer"
                  key={text.text}
                  onClick={text.onClick}
                >
                  <p className={clsx([text.className, "mb16"])}>
                    {t(text.text)}
                  </p>
                </a>
              ))}
            </div>
          </div>
          <div className="row flex1 gap32 flex-wrap ">
            <div className="row flex1">
              {width > 640 && <div className="flex1" />}
              <div>
                <p className="regular18-text color-grey mb16">
                  {t("Follow Us")}
                </p>
                <div className="row gap16">
                  <a href="https://www.facebook.com/Borbaloapp">
                    <img
                      src={FacebookSvg}
                      alt="facebook"
                      width={25}
                      height={25}
                    />
                  </a>
                  <a href="https://www.tiktok.com/@borbaloapp">
                    <img src={TiktokSvg} alt="tiktok" width={25} height={25} />
                  </a>
                  <a href="https://www.youtube.com/@borbalo">
                    <img
                      src={YoutubeSvg}
                      alt="youtube"
                      width={25}
                      height={25}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className={clsx("column flex1", {
                "ai-flex-end": width > 640,
              })}
            >
              <div className="mw185">
                <p className="regular18-text color-grey mb16">{t("Hotline")}</p>
                <a href="tel:+995322055015" className="medium18-text mb4">
                  +995 32 2 05 50 15
                </a>
                <p className="regular16-text color-grey mb16">
                  {t("Mon. - Sat.")}
                  <br />
                  {t("11:00 – 18:00 • Tbilisi")}
                </p>
                <a
                  href="mailto:kitxvebi@borbalo.ge"
                  className="medium18-text mb4"
                >
                  kitxvebi@borbalo.ge
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row flex-wrap jc-space-between gap32 mw1400 flex1">
          <p className="regular14-text color-grey mb16">
            {t("© 2024 KJ Group“")}
          </p>
        </div>
      </footer>
    </>
  );
};

export default RouteWithNavBar;
