import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
// import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useWindowDimensions from "hooks/useWindowDimensions";

import "./styles.scss";

gsap.registerPlugin(ScrollTrigger);

function CoinsGsap() {
  const { i18n } = useTranslation();
  // const [isShow, setIsShow] = useState(false);
  const { width } = useWindowDimensions();
  const canvas = useRef<HTMLCanvasElement>(null);
  const context = canvas.current?.getContext("2d");
  if (context) {
    context.imageSmoothingQuality = "high";
    context.imageSmoothingEnabled = true;
  }
  // const [images, setImages] = useState<HTMLImageElement[]>([]);
  // const airpods = {
  //   frame: 0,
  // };
  // const frameCount = 106;
  const canvasWidth = width > 640 ? 570 : width - 8;
  const canvasHeight = canvasWidth;

  const image1Style =
    width > 640
      ? undefined
      : {
          right: (width - 230) * 0.29,
          top: (width - 430) * 0.31,
        };

  const image2Style =
    width > 640
      ? undefined
      : {
          bottom: (width - 320) * 0.18,
          left: (width - 220) * 0.18,
        };

  // useEffect(() => {
  //   const mobileQrComponent = document.getElementById("mobile-qr-component");
  //   if (mobileQrComponent) {
  //     mobileQrComponent.className = isShow
  //       ? "dowload-app-wrapper active"
  //       : "dowload-app-wrapper";
  //   }
  // }, [isShow]);

  useEffect(() => {
    if (canvas.current) {
      canvas.current.width = canvasWidth;
      canvas.current.height = canvasHeight;
    }
  }, [canvasWidth, canvas.current]);

  // const render = useCallback(() => {
  //   if (context && canvas.current) {
  //     context.clearRect(0, 0, canvas.current?.width, canvas.current?.height);
  //     const img = images[airpods.frame];
  //     if (airpods.frame > 95) {
  //       setIsShow(true);
  //     } else {
  //       setIsShow(false);
  //     }

  //     if (img) {
  //       context.drawImage(img, 0, 0, canvasWidth, canvasWidth);
  //     }
  //   }
  // }, [context, images, canvasWidth]);

  // const currentFrame = (index: number) =>
  //   `/assets/coins/${(index + 1).toString().padStart(3, "0")}.jpg`;

  // useEffect(() => {
  //   if (canvas.current) {
  //     const imgs = [];
  //     for (let i = 0; i < frameCount; i++) {
  //       const img = new Image(1024, 1024);
  //       img.style.resize = "contain";

  //       img.src = currentFrame(i);
  //       if (i === 0) {
  //         img.onload = render;
  //       }
  //       imgs.push(img);
  //     }
  //     setImages(imgs);
  //   }
  // }, [canvas.current, canvasWidth, canvasHeight]);

  // const [start, setStart] = useState("-=60 top");

  // useEffect(() => {
  //   if (width > 1200) {
  //     setStart("-=60 top");
  //   } else if (width > 640) {
  //     setStart("=400 top");
  //   } else {
  //     setStart("=100 top");
  //   }
  // }, [width]);

  // useEffect(() => {
  //   return () => {
  //     gsap.killTweensOf(airpods);
  //   };
  // }, []);

  // useGSAP(
  //   () => {
  //     gsap.to(airpods, {
  //       frame: frameCount - 1,
  //       snap: "frame",
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: ".canvas-container",
  //         start: start,
  //         end: "+=3200",
  //         pin: true,
  //         invalidateOnRefresh: true,
  //         scrub: 0.5,
  //       },
  //       onUpdate: e => {
  //         render();
  //       },
  //     });
  //   },
  //   { dependencies: [context] },
  // );

  return (
    <>
      <div className="right" style={{ width: canvasWidth }}>
        {/* <canvas
          ref={canvas}
          id="hero-lightpass"
          style={{ width: canvasWidth, height: canvasHeight }}
        ></canvas> */}
        <img src={require("./assets/coins.jpg")} className="coins" alt="logo" />
        <div
          className="images"
          style={{
            width: canvasWidth,
            height: canvasHeight,
            opacity: "0.3",
          }}
        >
          <img
            src={
              i18n.language === "ge"
                ? require("./assets/section1-fine.svg").default
                : require("./assets/section1-fine-en.svg").default
            }
            alt="right"
            style={image1Style}
          />
          <img
            src={
              i18n.language === "ge"
                ? require("./assets/section1-borbalo.svg").default
                : require("./assets/section1-borbalo-en.svg").default
            }
            alt="left"
            style={image2Style}
          />
        </div>
      </div>
    </>
  );
}

export default CoinsGsap;
