import QrComponent from "pages/Home/QrComponent";
import "./styles.scss";
import LogoSvg from "assets/icons/logo.svg";
import useIsVisible from "hooks/useIsVisible";
import { useTranslation } from "react-i18next";

function Section5() {
  const { t } = useTranslation();
  const { elementRef } = useIsVisible("Download");
  return (
    <div id="Download" className="section5 mb128" ref={elementRef}>
      <img
        src={LogoSvg}
        width="77px"
        height="77px"
        alt="logo"
        className="mb32"
      />
      <h2 className="medium50-text mb16 color-black text-align-center">
        {t("Borbalo — application")}
        <br />
        {t("You Can Trust")}
      </h2>
      <p className="regular20-text color-black text-align-center mb32">
        {t("Join tens of thousands of drivers who rely on Borbalo daily")}
      </p>
      <QrComponent isMobileButton={false} />
    </div>
  );
}

export default Section5;
